// Our main CSS
import '../css/app.css'

/**
const ExampleVueComponentWrapper = document.getElementById(
  'ExampleVueComponentWrapper'
)
if (ExampleVueComponentWrapper && ExampleVueComponentWrapper !== null) {
  import('./ExampleVueComponent/index.js')
    .then((module) => {
      module.default(ExampleVueComponentWrapper as HTMLElement)
    })
    .catch((err) => {
      console.log(err)
      // main.textContent = err.message;
    })
}
*/

/**
 * 🦄: Now do your magic.
 */

const VideoAppWrapper = document.getElementById('VideoAppWrapper')
if (VideoAppWrapper && VideoAppWrapper !== null) {
  import('./Video/index.ts')
    .then((module) => {
      module.default(VideoAppWrapper as HTMLElement)
    })
    .catch((err) => {
      console.log(err)
    })
}
